<template>
  <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full md:px-24 lg:px-16 lg:py-20 dark:bg-gray-900">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-center">
      <div class="text-center">
        <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl dark:text-gray-300">20+</h6>
        <p class="text-sm font-medium tracking-widest text-gray-800 dark:text-gray-300 uppercase lg:text-base">
          Proje
        </p>
      </div>
      <div class="text-center">
        <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl dark:text-gray-300">100+</h6>
        <p class="text-sm font-medium tracking-widest text-gray-800 dark:text-gray-300 uppercase lg:text-base">
          MÜŞTERİ
        </p>
      </div>
      <div class="text-center">
        <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl dark:text-gray-300">600+</h6>
        <p class="text-sm font-medium tracking-widest text-gray-800 dark:text-gray-300 uppercase lg:text-base">
          Pano
        </p>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "WorkStats",
    };
</script>