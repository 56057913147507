<template>
    <section class="bg-white dark:bg-gray-900 pb-8">
        <div class="container px-6 py-12 mx-auto">
            <h1 class="text-2xl font-semibold text-gray-800 lg:text-3xl dark:text-white mx-4 md:mx-16">
                Sıkça Sorulan Sorular.
            </h1>

            <div class="grid grid-cols-1 gap-8 mt-8 lg:mt-16 md:grid-cols-2 xl:grid-cols-3 mx-4 md:mx-16">
                <div>
                    <div class="inline-block p-3 text-white bg-blue-600 rounded-lg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>

                    <div>
                        <h1 class="text-xl font-semibold text-gray-700 dark:text-white">
                            Fiyatlandırma politikanız nedir?
                        </h1>

                        <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                            Projenizin özel ihtiyaçlarını göz önünde bulundurarak, yüksek
                            kalite standartlarını korurken aynı zamanda bütçenize uygun
                            çözümler sunmaya odaklanıyoruz. Detaylı bilgi için bizimle
                            iletişime geçebilirsiniz.
                        </p>
                    </div>
                </div>

                <div>
                    <div class="inline-block p-3 text-white bg-blue-600 rounded-lg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>

                    <div>
                        <h1 class="text-xl font-semibold text-gray-700 dark:text-white">
                            Ne zaman hizmet vermeye başladınız?
                        </h1>

                        <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                            2024 yılında faaliyete geçen firmamız, inşaat sektöründe 10 yılı
                            aşkın tecrübeye sahip bir ekipten oluşmaktadır. Bu süre zarfında,
                            konut, ticari ve kamu binaları gibi çeşitli projelerde yer alarak
                            müşterilerimizin memnuniyetini kazanmayı başardık. Kaliteli
                            işçiliğimiz, zamanında teslimatımız ve müşteri odaklı yaklaşımımız
                            ile sektörde güvenilir bir marka haline geldik.
                        </p>
                    </div>
                </div>

                <div>
                    <div class="inline-block p-3 text-white bg-blue-600 rounded-lg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>

                    <div>
                        <h1 class="text-xl font-semibold text-gray-700 dark:text-white">
                            Hangi faliyet alanlarında hizmet veriyorsunuz?
                        </h1>

                        <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                            Şirketimiz, enerji ve otomasyon sektörlerine kapsamlı çözümler
                            sunmaktadır. Uzman ekibimiz, elektrik, otomasyon, arıza bakımı,
                            makine revizyonu ve pano imalatı gibi alanlarda geniş bir yelpazede
                            hizmet vermektedir. Müşterilerimizin ihtiyaçlarını analiz ederek,
                            en uygun ve verimli çözümleri sunmaya odaklanmaktayız.
                        </p>
                    </div>
                </div>

                <div>
                    <div class="inline-block p-3 text-white bg-blue-600 rounded-lg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>

                    <div>
                        <h1 class="text-xl font-semibold text-gray-700 dark:text-white">
                            Hangi bölgelere hizmet veriyorsunuz?
                        </h1>

                        <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                            Şu anda Sakarya, Kocaeli, İstanbul, Bolu, Bursa, Bilecik ve Düzce
                            illerine elektrik, otomasyon, arıza bakımı ve onarımı, makine
                            revizyonu ve modernizasyonu, pano imalatı ve tasarımı gibi alanlarda
                            hizmet vermekteyiz. Hizmet ağımızı genişletmeye devam etmekteyiz ve
                            talepler doğrultusunda Türkiye'nin farklı bölgelerine de hizmet
                            vermeye hazırız. Müşterilerimizle kurduğumuz uzun vadeli ilişkiler,
                            sektördeki güvenilirliğimizin bir göstergesidir.
                        </p>
                    </div>
                </div>

                <div>
                    <div class="inline-block p-3 text-white bg-blue-600 rounded-lg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>

                    <div>
                        <h1 class="text-xl font-semibold text-gray-700 dark:text-white">
                            Ekibinizin büyüklüğü nedir?
                        </h1>

                        <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                            Şu anda deneyimli iki kişilik bir ekipten oluşan sağlam bir temele
                            sahibiz. Projelerin büyüklüğüne ve ihtiyaca göre ekibimizi
                            genişletme ve esnek bir şekilde çalışabilme yeteneğine sahibiz.
                            Geniş bir iş ortakları ve alt yükleniciler ağıyla birlikte, her
                            türlü projeye hızlı ve etkili bir şekilde cevap verme imkanına
                            sahibiz.
                        </p>
                    </div>
                </div>

                <div>
                    <div class="inline-block p-3 text-white bg-blue-600 rounded-lg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </div>

                    <div>
                        <h1 class="text-xl font-semibold text-gray-700 dark:text-white">
                            Ekibinizin deneyim seviyesi nedir?
                        </h1>

                        <p class="mt-2 text-sm text-gray-500 dark:text-gray-300">
                            Ekibimiz 10 yılı aşkın süredir elektrik ve otomasyon sektöründe
                            faaliyet göstermektedir. Bu süre zarfında, birçok farklı projede
                            yer alarak geniş bir tecrübe kazanmıştır. Müşterilerimizin
                            ihtiyaçlarını en iyi şekilde anlamak ve en uygun çözümleri sunmak
                            için sürekli olarak kendimizi geliştirmekteyiz.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "FAQ",
    };
</script>