<template>
  <TopBar></TopBar>
  <CTA></CTA>
  <WhyUs></WhyUs>
  <WorkStats></WorkStats>
  <MainWorkAreas></MainWorkAreas>
  <LastWorks></LastWorks>
  <FAQ></FAQ>
  <PageFooter></PageFooter>
</template>

<script>
import TopBar from '../../components/TopBar.vue'
import CTA from './homepagecomponents/CTA.vue'
import WhyUs from './homepagecomponents/WhyUs.vue'
import WorkStats from './homepagecomponents/WorkStats.vue'
import MainWorkAreas from './homepagecomponents/MainWorkAreas.vue'
import LastWorks from './homepagecomponents/LastWorks.vue'
import FAQ from './homepagecomponents/FAQ.vue'
import PageFooter from '../../components/Footer.vue'
export default {
  name: 'App',
  components: {
    TopBar,
    CTA,
    WhyUs,
    WorkStats,
    MainWorkAreas,
    LastWorks,
    FAQ,
    PageFooter
  }
}
</script>

<style>

</style>
