<template>
    <footer class="bg-white dark:bg-gray-900">
        <div class="container p-6 mx-auto">
            <div class="lg:flex">
                <div class="w-full -mx-6 lg:w-2/5">
                    <div class="px-6">
                        <div class="flex items-center">
                            <a href="/">
                                <img class="w-auto h-14" :src="logo" alt="Logo">
                            </a>
                            <a href="/">
                                <h1 class="px-3 text-gray-600 dark:text-gray-200 text-xl font-mono">Özbağ Otomasyon</h1>
                            </a>
                        </div>


                        <p class="max-w-sm mt-2 text-gray-500 dark:text-gray-400">Bizden haberdar olmak için sosyal
                            medya hesaplarımızı takip edebilirsiniz.</p>

                        <div class="flex mt-6 -mx-2">
                            <a href="https://www.instagram.com/ozbagotomasyon"
                                class="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                                aria-label="Instagram">
                                <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24"
                            viewBox="0 0 24 24" >
                            <path
                                d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z">
                            </path>
                        </svg>
                            </a>

                            <a href="https://www.twitter.com/ozbagotomasyon"
                                class="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                                aria-label="X">
                                <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 30 30"> <path d="M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z"></path> </svg>
                            </a>

                            <a href="#"
                                class="mx-2 text-gray-600 transition-colors duration-300 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                                aria-label="Facebook">
                                <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50"> <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path> </svg>
                            </a>

                        </div>
                    </div>
                </div>

                <div class="mt-6 lg:mt-0 lg:flex-1">
                    <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        <div>
                            <h3 class="text-gray-700 uppercase dark:text-white">HAKKINDA</h3>
                            <a href="#"
                                class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Kurumsal</a>
                            <a href="/projeler"
                                class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Projeler</a>
                            <a href="#"
                                class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Gizlilik
                                Politikası</a>
                        </div>

                        <div>
                            <h3 class="text-gray-700 uppercase dark:text-white">Blog</h3>
                            <a href="#"
                                class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Teknoloji</a>
                            <a href="#"
                                class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Elektrik</a>
                            <a href="#"
                                class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Videolar</a>
                        </div>

                        <div>
                            <h3 class="text-gray-700 uppercase dark:text-white">HİZMETLER</h3>
                            <a href="#"
                                class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Elektrik</a>
                            <a href="#"
                                class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Otomasyon</a>
                            <a href="#"
                                class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">Makine
                                Revizyon</a>
                        </div>

                        <div>
                            <h3 class="text-gray-700 uppercase dark:text-white">İLETİŞİM</h3>
                            <a href="tel:+905455145401" class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">+90 545 514 54 01</a>
                            <a href="mailto:info@ozbagotomasyon.com.tr" class="block mt-2 text-sm text-gray-600 dark:text-gray-400 hover:underline">info@ozbagotomasyon.com.tr</a>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="h-px my-6 bg-gray-200 border-none dark:bg-gray-700">

            <div>
                <p class="text-center text-gray-500 dark:text-gray-400">© Özbağ Elektrik & Otomasyon 2024 - Tüm Hakları
                    Saklıdır</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'PageFooter',
    data() {
        return {
            logo: ''
        }
    },
    mounted() {
        this.setThemeLogo();
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.setThemeLogo);
    },
    methods: {
        setThemeLogo() {
            const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
            this.logo = prefersDarkScheme ? require('../assets/Icon3.svg') : require('../assets/light.svg');
        }
    }
}
</script>