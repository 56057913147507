<template>
    <section>
        <div class="Flex p-4 mx-auto bg-white max-auto sm:p-6 lg:p-8 dark:bg-gray-900">
            <div class="flex flex-wrap mx-4 sm:mx-8 md:mx-16 lg:mx-20 xl:mx-40">
                <div class="w-full px-4 lg:w-1/2">
                    <div class="pb-8 mb-8 border-b lg:mb-0 lg:pb-0 lg:border-b-0">
                        <h2 class="mb-4 text-2xl font-bold lg:text-3xl font-heading dark:text-white">
                            Projenizin Potansiyelini En Yükseğe Taşıyın
                        </h2>
                        <p class="mb-4 leading-loose text-gray-500 dark:text-gray-300">
                            Projelerinizde doğru otomasyon çözümleriyle enerji verimliliğini artırabilir, iş
                            süreçlerinizi optimize edebilir ve güvenliği sağlayabilirsiniz. Bununla birlikte, projenizin
                            devamlılığı ve çalışabilirliği de kritik öneme sahiptir. Uzman ekibimiz, size özel
                            çözümlerle projenizin başarısını en üst seviyeye taşır.
                        </p>
                        <div class="w-full md:w-1/2">
                            <button type="button"
                                class="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg" @click="Services">
                                Daha Fazla
                            </button>
                        </div>
                    </div>
                </div>
                <div class="w-full px-4 lg:w-1/2">
                    <ul class="space-y-8">
                        <li class="flex -mx-4">
                            <div class="px-4">
                                <span
                                    class="flex items-center justify-center w-12 h-12 mx-auto text-xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                    1
                                </span>
                            </div>
                            <div class="px-4">
                                <h3 class="my-2 text-lg font-semibold dark:text-white">
                                    Yüksek Kalite
                                </h3>
                                <p class="leading-loose text-gray-500 dark:text-gray-300">
                                    Yüksek kalite standartlarımız sayesinde projelerinizde en iyi çözümleri sunuyoruz.
                                </p>
                            </div>
                        </li>
                        <li class="flex -mx-4">
                            <div class="px-4">
                                <span
                                    class="flex items-center justify-center w-12 h-12 mx-auto text-xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                    2
                                </span>
                            </div>
                            <div class="px-4">
                                <h3 class="my-2 text-lg font-semibold dark:text-white">
                                    Profesyonel Ekip
                                </h3>
                                <p class="leading-loose text-gray-500 dark:text-gray-300">
                                    Profesyonel ekibimiz sayesinde projenizin başarısını en üst seviyeye çıkartın!
                                </p>
                            </div>
                        </li>
                        <li class="flex -mx-4">
                            <div class="px-4">
                                <span
                                    class="flex items-center justify-center w-12 h-12 mx-auto text-xl font-bold text-blue-600 rounded-full font-heading bg-blue-50">
                                    3
                                </span>
                            </div>
                            <div class="px-4">
                                <h3 class="my-2 text-lg font-semibold dark:text-white">
                                    Yenilikçi Yaklaşımlar
                                </h3>
                                <p class="leading-loose text-gray-500 dark:text-gray-300">
                                    Yenilikçi yaklaşımlarımızla projelerinizde fark yaratın. Daima öncü olmanın verdiği
                                    avantajla, işlerinizi yeni ufuklara taşıyın.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>




<script>
export default {
    name: 'WhyUs',
    methods: {
        Services() {
            window.location.href = '/hizmetler'
        }
    }
}
</script>