<template>
    <section class="bg-white dark:bg-gray-900">

    <div class="container px-6 py-16 mx-auto text-center">
        <div class="max-w-lg mx-auto">
            <h1 class="text-3xl font-semibold text-gray-800 dark:text-white lg:text-4xl">Yeni Projenizi Beraber Geliştirelim</h1>
            <p class="mt-6 text-gray-500 dark:text-gray-300">Otomasyon ve elektrikte güvenilir bir ekip arıyorsanız, doğru yerdesiniz. Projelerinizi bizimle hayata geçirin.</p>
            <button class="px-5 py-2 mt-6 text-sm font-medium leading-5 text-center text-white capitalize bg-blue-600 rounded-lg hover:bg-blue-500 lg:mx-0 lg:w-auto focus:outline-none" @click="contactUs">
                İletişime Geç
            </button>
        </div>

        <div class="flex justify-center mt-10">
            <img class="object-cover w-full h-96 rounded-xl lg:w-4/5" src="https://images.unsplash.com/photo-1570155308259-f4480a5c3696?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
        </div>
    </div>
</section>
</template>

<script>
    export default {
        name: 'CTA',
        methods: {
            contactUs() {
                window.location.href = '/iletisim'
            }
        }
    }
</script>