<template>
<section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <h1 class="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl dark:text-white">Son Projelerimiz
        </h1>

        <p class="mt-4 text-center text-gray-500 dark:text-gray-300">
            
        </p>


        <div class="grid grid-cols-1 gap-8 mx-4 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
            <a href="/projeler/proje.html?type=guc">
                <ProjectComponent title="Güç Panoları" category="Otomasyon" imageUrl="https://api.ozbagotomasyon.com.tr/image/OtomasyonPano.jpg"/>
            </a>
            <a href="/projeler/proje.html?type=aydinlatma">
                <ProjectComponent title="Aydınlatma Panoları" category="Otomasyon" imageUrl="https://api.ozbagotomasyon.com.tr/image/GucAydinlatmaDagitimPanosu8.jpg"/>
            </a>
            <a href="/projeler/proje.html?type=kumanda">
                <ProjectComponent title="Kumanda Panoları" category="Otomasyon" imageUrl="https://api.ozbagotomasyon.com.tr/image/KumandaPanoYatay.jpg"/>
            </a>
        </div>
    </div>
</section>

</template>

<script>
import ProjectComponent from './ProjectComponent.vue'
export default {
    name: 'LastWorks',
    components: {
        ProjectComponent
    }
}
</script>