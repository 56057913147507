<template>
    <div class="overflow-hidden bg-cover rounded-lg cursor-pointer h-96 group" :style="{ backgroundImage: `url(${imageUrl})` }">
      <div class="flex flex-col justify-center w-full h-full px-8 py-4 transition-opacity duration-700 opacity-0 backdrop-blur-sm bg-gray-800/60 group-hover:opacity-100">
        <h2 class="mt-4 text-xl font-semibold text-white capitalize">{{ title }}</h2>
        <p class="mt-2 text-lg tracking-wider text-blue-400 uppercase">Otomasyon</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      imageUrl: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      }
    }
  };
  </script>