<template>
    <div class="Flex max-w-auto p-4 px-4 mx-auto bg-white dark:bg-gray-900 sm:px-6 lg:px-8 py-26">
        <div class="relative mx-4 lg:mx-24">
            <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-2 lg:items-center">
                <div class="ml-auto lg:col-start-2 lg:max-w-2xl">
                    <p class="text-base font-semibold leading-6 text-indigo-500 uppercase">
                        Özbağ Otomasyon
                    </p>
                    <h4 class="mt-2 text-2xl font-extrabold leading-8 text-gray-900 dark:text-white sm:text-3xl sm:leading-9">
                        Ana Faaliyet Alanlarımız
                    </h4>
                    <p class="mt-4 text-lg leading-6 text-gray-500 dark:text-gray-300">
                        Birçok alanda faaliyet gösteren firmamız, en iyi hizmeti sunmak için çalışmaktadır.
                    </p>
                    <ul class="gap-6 mt-8 md:grid md:grid-cols-2">
                        <li class="mt-6 lg:mt-0">
                            <div class="flex">
                                <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full dark:text-green-500 drark:bg-transparent">
                                    <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                <span class="ml-4 text-base font-medium leading-6 text-gray-500 dark:text-gray-200">
                                    Elektrik
                                </span>
                            </div>
                        </li>
                        <li class="mt-6 lg:mt-0">
                            <div class="flex">
                                <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full dark:text-green-500 drark:bg-transparent">
                                    <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                <span class="ml-4 text-base font-medium leading-6 text-gray-500 dark:text-gray-200">
                                    Otomasyon
                                </span>
                            </div>
                        </li>
                        <li class="mt-6 lg:mt-0">
                            <div class="flex">
                                <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full dark:text-green-500 drark:bg-transparent">
                                    <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                <span class="ml-4 text-base font-medium leading-6 text-gray-500 dark:text-gray-200">
                                    Arıza Bakım
                                </span>
                            </div>
                        </li>
                        <li class="mt-6 lg:mt-0">
                            <div class="flex">
                                <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full dark:text-green-500 drark:bg-transparent">
                                    <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                <span class="ml-4 text-base font-medium leading-6 text-gray-500 dark:text-gray-200">
                                    Makine Revizyon
                                </span>
                            </div>
                        </li>
                        <li class="mt-6 lg:mt-0">
                            <div class="flex">
                                <span class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-800 bg-green-100 rounded-full dark:text-green-500 drark:bg-transparent">
                                    <svg class="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd">
                                        </path>
                                    </svg>
                                </span>
                                <span class="ml-4 text-base font-medium leading-6 text-gray-500 dark:text-gray-200">
                                    Pano İmalatı
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="relative mt-10 lg:-mx-4 relative-20 lg:mt-0 lg:col-start-1">
                    <div class="relative space-y-4">
                        <div class="flex items-end justify-center space-x-4 lg:justify-start">
                            <img class="w-32 rounded-lg shadow-lg md:w-56" width="200" src="https://images.unsplash.com/photo-1571989928659-6da14ecfec42?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="1"/>
                            <img class="w-40 rounded-lg shadow-lg md:w-64" width="260" src="https://images.unsplash.com/photo-1635335874521-7987db781153?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="2"/>
                        </div>
                        <div class="flex items-start justify-center ml-4 space-x-4 lg:justify-start">
                            <img class="w-24 rounded-lg shadow-lg md:w-40" width="170" src="https://images.unsplash.com/photo-1601462904263-f2fa0c851cb9?q=80&w=1878&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="3"/>
                            <img class="w-32 rounded-lg shadow-lg md:w-56" width="200" src="https://images.unsplash.com/photo-1544724569-5f546fd6f2b5?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="4"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MainWorkAreas'
    }
</script>